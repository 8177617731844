import React from 'react'
import { useTranslation } from 'react-i18next'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SignUpButton from '../components/signUpButton/SignUpButton'
// import WaitlistForm from '../components/waitlistForm/WaitlistForm'
import DealerWaitlistForm from '../components/dealerWaitlistForm/DealerWaitlistForm'

import Header from '../assets/svg/header.svg'
// import Enveloppe from '../assets/svg/enveloppe.svg'
import Notification from '../assets/svg/notification.svg'
import Conversation from '../assets/svg/conversation.svg'

const PreOrderPage = () => {
    const { t } = useTranslation('preorder')
    return (
        <Layout>
            <SEO title="Waitlist" description="preorder:description" />
            <div className="preOrderHeader">
                <Header className="preOrderHeaderBackground" />
                <div className="preOrderHeaderImage">
                    <StaticImage
                        alt={t('boxTitle')}
                        className="preOrderHeaderSubImage"
                        src="../assets/images/box.png"
                    />
                    <StaticImage
                        alt={t('cameraTitle')}
                        className="preOrderHeaderSubImage"
                        src="../assets/images/camera.png"
                    />
                    <StaticImage
                        alt={t('alarmTitle')}
                        className="preOrderHeaderSubImage"
                        src="../assets/images/alarm.png"
                    />
                </div>
                <div className="preOrderHeaderTitles">
                    <h1 className="preOrderTitle">{t('header')}</h1>
                    <p className="preOrderDescription">{t('description')}</p>
                    <SignUpButton text="Join our waitlist" />
                </div>
            </div>
            <div className="card dealerWaitlistSection" id="signUp">
                <h3 className="dealerTitle">{t('contact:joinWaitlist')}</h3>
                <div className="dealerFormSection">
                    <p className="dealerDescription">{t('dealerDescription')}</p>
                    <DealerWaitlistForm />
                </div>
            </div>
            <div className="moreInfoSection">
                <h2 className="moreInfoTitle">{t('keepYou')}</h2>
                <div className="cardGrid">
                    <div className="card">
                        <Notification />
                        <h3 className="cardTitle">{t('upToDate')}</h3>
                        <hr />
                        <p className="cardDescription">{t('upToDateDescription')}</p>
                    </div>
                    <div className="card">
                        <Conversation />
                        <h3 className="cardTitle">{t('moreInfo')}</h3>
                        <hr />
                        <p className="cardDescription">{t('moreInfoDescription')}</p>
                    </div>
                </div>
            </div>
            {/* <div className="waitlistSection" id="signUp">
                <Enveloppe className="enveloppe" />
                <div className="waitlistFormSection">
                    <h3 className="waitlistTitle">{t('contact:joinWaitlist')}</h3>
                    <WaitlistForm />
                </div>
            </div> */}
        </Layout>
    )
}

export default PreOrderPage
