import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material'

import AllInputProps from '../../utils/inputProps'
import currentLanguages from '../../i18n/config/currentLanguages'
import { checkboxClasses, formControlLabelClasses } from '../../utils/checkboxProps'

import Checked from '../../assets/svg/checkbox/checked.svg'
import Unchecked from '../../assets/svg/checkbox/unchecked.svg'

const DealerWaitlistForm = () => {
    const [dealer, setDealer] = useState(false)
    const { t, i18n } = useTranslation('contact')
    const currentLangPath = currentLanguages.find(lang => lang.shorthand === i18n.language)?.path

    return (
        <FormControl
            component="form"
            name="kourai-waitlist"
            method="post"
            className="dealerForm"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action={`${currentLangPath}/thank-you`}
        >
            {/* Need to have this hidden input with the form name to your JSX form */}
            <input type="hidden" name="form-name" value="kourai-waitlist" />
            <TextField
                required
                fullWidth
                name="Name"
                type="name"
                id="waitlistForm-name"
                label={t('name')}
                variant="filled"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...AllInputProps}
            />
            <TextField
                required
                fullWidth
                name="Email"
                type="email"
                id="waitlistForm-email"
                label={t('email')}
                variant="filled"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...AllInputProps}
            />
            {dealer ? (
                <>
                    <TextField
                        required
                        fullWidth
                        name="Company"
                        type="name"
                        id="waitlistForm-company"
                        label={t('company')}
                        variant="filled"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...AllInputProps}
                    />
                    <TextField
                        required
                        fullWidth
                        name="City"
                        type="name"
                        id="waitlistForm-city"
                        label={t('city')}
                        variant="filled"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...AllInputProps}
                    />
                    <TextField
                        fullWidth
                        name="Phone"
                        type="tel"
                        id="waitlistForm-phone"
                        label={t('phone')}
                        variant="filled"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...AllInputProps}
                    />
                    <TextField
                        fullWidth
                        name="Postal Code"
                        type="text"
                        id="waitlistForm-postalcode"
                        label={t('postal')}
                        variant="filled"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...AllInputProps}
                    />
                    <TextField
                        fullWidth
                        id="waitlistForm-message"
                        label={t('message')}
                        name="Message"
                        multiline
                        rows={4}
                        variant="filled"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...AllInputProps}
                    />
                </>
            ) : (
                <>
                    <input type="hidden" name="Company" />
                    <input type="hidden" name="City" />
                    <input type="hidden" name="Phone" />
                    <input type="hidden" name="Postal Code" />
                    <input type="hidden" name="Message" />
                </>
            )}
            <FormControlLabel
                classes={formControlLabelClasses}
                label={t('preorder:dealerCheckboxLabel')}
                control={
                    <Checkbox
                        id="waitlistForm-dealer"
                        name="Dealer"
                        checked={dealer}
                        classes={checkboxClasses}
                        checkedIcon={<Checked />}
                        icon={<Unchecked />}
                        onChange={e => setDealer(e.target.checked)}
                    />
                }
            />
            <Button type="submit" variant="contained" disableElevation className="signUpButton">
                {t('submit')}
            </Button>
        </FormControl>
    )
}

export default DealerWaitlistForm
