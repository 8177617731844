const formControlLabelClasses = {
    root: 'formControlLabelRoot',
    label: 'formControlLabelLabel',
}

const checkboxClasses = {
    root: 'checkboxRoot',
    checked: 'checkboxChecked',
}

export { formControlLabelClasses, checkboxClasses }
